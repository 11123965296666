@import "./fonts";
@import "./variable";
@import "./mixin";

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background: $defaultcolor;
  font-weight: 500;
}
p, h2, h1, h3, h4, h5{
  padding: 0;
  margin: 0;
}
.img {
  @include img;
}
.mlauto {
  margin-left: auto !important;
}
.mrauto {
  margin-right: auto !important;
}
.margin10 {
  margin-top: 10px;
}
.margin20 {
  margin-top: 20px !important;
}
.margin30 {
  margin-top: 30px !important;
}
.margin40 {
  margin-top: 40px;
}
.pb10 {
  padding-bottom: 10px;
}
.margin50 {
  margin-top: 50px !important;
}
.marginLeft10 {
  margin-left: 10px;
}
.mtm30 {
  margin-top: -30px !important;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 540px;
    width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
    width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
    width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
    width: 1140px;
  }
}

input,
textarea,
select {
  font-family: 'Poppins', sans-serif;
}

.containerfluid {
  max-width: 90%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.ul {
  @include ul;
}
.cdark {
  color: $blackcolor !important;
}
.cwhite {
  color: $defaultcolor !important;
}
.btn {
  border: none;
  outline: none;
  transition: all ease-in-out 0.5s;
  -webkit-transition: all ease-in-out 0.5s;
  padding: 15px 30px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  font-size: 17px;
  text-decoration: none;
}
.btn:hover {
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}
.btn:disabled {
  // box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2);
  // transform: translateY(-2px);
  pointer-events: none;
  background: #fff;
  color: #000;
  border: solid 1px $grey;
}
.shopBtn {
  background: $orengecolor;  
  margin-top: 20px;
  color: $defaultcolor;
  display: inline-block;
  font-weight: 400;
  &:hover {
    background: $green;
  }
}
.subscribeBtn {
  background: $normalbluecolor;  
  margin-top: 10px;
  color: $defaultcolor;
  display: inline-block;
  font-weight: 400;
  padding: 10px 20px;
  border-radius: 3px;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  &:hover {
    background: $green;
  }
}
.applyBtn {
  background: $defaulblackbg;
  text-transform: uppercase;
  margin-top: 20px;
  color: $defaultcolor;
  display: inline-block;
  &:hover {
    background: $blackcolor;
  }
}

.uploadBtn {
  background: $lightpink;
  text-transform: uppercase;
  margin: 10px 0 20px;
  color: $defaultcolor;
  display: inline-block;
  border: none;
  &:disabled {
    background: $softlightgrey;
    color: $blackcolor;
    border: none;
  }
  &:hover {
    background: $blackcolor;
  }
}
.pinkBtn {
  background: $lightpink;
  text-transform: uppercase;
  margin: 10px 0 20px;
  color: $defaultcolor;
  display: inline-block;
  &:hover {
    background: $blackcolor;
  }
}
.orangeBtn {
  background: #dd6800;
  color: #ffffff;
  display: inline-block;
  padding: 13px 30px;
  &:hover {
    color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3) !important;
    transform: translateY(-4px);
  }
}
.orangeBtn2 {
  background: #dd6800;
  font-size: 15px;
  color: #ffffff;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
  padding: 13px 20px;
  &:hover {
    color: #ffffff;
    background-color: #303030;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3) !important;
    transform: translateY(-4px);
  }
}
.lightGreenBtn {
  background: #1cb594;
  color: #ffffff;
  display: inline-block;
  padding: 13px 30px;
  &:hover {
    color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3) !important;
    transform: translateY(-4px);
  }
}
.lightGreenBtn2 {
  background: #1cb594;
  font-size: 15px;
  color: #ffffff;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
  padding: 13px 20px;
  &:hover {
    color: #ffffff;
    background-color: #303030;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3) !important;
    transform: translateY(-4px);
  }
}
.delBtnSt {
  text-transform: uppercase;
  margin: 10px 0 20px;
  color: $defaultcolor;
  display: inline-block;
  border: solid 1px $red;
  background: transparent;
  color: $red;
  margin-right: 10px;
  @include flex;
  align-items: center;
  font-weight: 500;
  svg {
    font-size: 1.2rem;
    margin-right: 3px;
    margin-top: -4px;
  }
  &:hover {
    background: $blackcolor;
    border: solid 1px $blackcolor;
    color: $defaultcolor;
  }
}
.greenBtnSt {
  text-transform: uppercase;
  margin: 10px 0 20px;
  color: $defaultcolor;
  display: inline-block;
  border: solid 1px $green;
  background: transparent;
  color: $green;
  margin-right: 10px;
  @include flex;
  align-items: center;
  font-weight: 500;
  svg {
    font-size: 1.1rem;
    margin-right: 3px;
    margin-top: -3px;
  }
  &:hover {
    background: $lightpink;
    border: solid 1px $lightpink;
    color: $defaultcolor;
  }
}

.pinkBtn3 {
  background: $lightpink;
  text-transform: uppercase;
  margin: 0;
  color: $defaultcolor;
  display: inline-block;
  margin-left: 10px;
  &:hover {
    background: $blackcolor;
  }
}
.exportBtnlight {
  background: $lightpink;
  text-transform: uppercase;
  color: $defaultcolor;
  display: inline-block;
  padding: 10px 25px;
  margin-left: 10px;
  font-size: 0.9rem;
  &:hover {
    background: $blackcolor;
  }
}
.filterBtnlight {
  background: $lightpink;
  text-transform: uppercase;
  color: $defaultcolor;
  display: inline-block;
  padding: 12px 25px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  &:hover {
    background: $blackcolor;
  }
}
.resetBtnlight {
  background: $blackcolor;
  text-transform: uppercase;
  color: $defaultcolor;
  display: inline-block;
  padding: 12px 25px;
  margin-left: 10px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background: $lightpink;
  }
}
.nextBtnlight {
  background: $lightpink;
  text-transform: uppercase;
  color: $defaultcolor;
  display: inline-block;
  padding: 13px 35px;
  margin-left: 10px;
  &:hover {
    background: $blackcolor;
  }
}
.nextBtnlight5 {
  background: $lightpink;
  text-transform: uppercase;
  color: $defaultcolor;
  display: inline-block;
  padding: 13px 15px;
  &:hover {
    background: $blackcolor;
    box-shadow: none;
    transform: none;
  }
}
.preBtnlight {
  background: $softlightgrey;
  text-transform: uppercase;
  color: $grey;
  display: inline-block;
  padding: 13px 35px;
  margin-right: 15px;
  &:hover {
    background: $blackcolor;
    color: $defaultcolor;
  }
}
.changeBtn {
  background: $softlightgrey;
  text-transform: uppercase;
  color: $blackcolor;
  display: inline-block;
  padding: 10px 20px;
  border: solid 1px $lightgrey;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
  font-size: 0.9rem;
  &:hover {
    background: $blackcolor;
    color: $defaultcolor;
  }
}

.delBtn {
  background: $blackcolor;
  text-transform: uppercase;
  margin-top: 20px;
  color: $defaultcolor;
  display: inline-block;
  padding: 10px 20px;
  &:hover {
    background: $blackcolor;
  }
}
.confirmBtn {
  background: $defaulblackbg;
  text-transform: uppercase;
  margin-top: 20px;
  color: $defaultcolor;
  display: inline-block;
  padding: 10px 20px;
  &:hover {
    background: $blackcolor;
  }
}
.pinkBtn2 {
  background: $lightpink;
  text-transform: uppercase;
  margin-top: 20px;
  color: $defaultcolor;
  display: inline-block;
  padding: 10px 20px;
  &:hover {
    background: $blackcolor;
  }
}
.upBtn {
  border: solid 1px $blackcolor;
  text-transform: uppercase;
  margin-top: 20px;
  color: $blackcolor;
  display: inline-block;
  padding: 10px 20px;
  &:hover {
    background: $lightpink;
    color: $defaultcolor;
    border: solid 1px $lightpink;
  }
}
.backBtn {
  border: solid 1px $blackcolor;
  text-transform: uppercase;
  margin-top: 20px;
  color: $blackcolor;
  display: inline-block;
  padding: 14px 30px;
  margin-right: 10px !important;
  &:hover {
    background: $lightpink;
    color: $defaultcolor;
    border: solid 1px $lightpink;
  }
}

.addproBtn {
  text-transform: uppercase;
  color: $defaultcolor;
  display: inline-block;
  margin-top: 10px;
  padding: 15px 20px;
  font-size: 0.9rem;
  font-weight: 500;
  border: solid 1px #ccc;
  border-radius: 8px;
  background: $blackcolor;
  &:hover {
    background: $lightpink;
    color: $defaultcolor;
    border: solid 1px $lightpink;
  }
}

.disableBtn {
  background: $softlightgrey;
  text-transform: uppercase;
  margin-top: 20px;
  color: $blackcolor;
  display: inline-block;
}

.nextBtn {
  background: $defaulblackbg;
  text-transform: uppercase;
  margin-top: 20px;
  color: $defaultcolor;
  display: inline-block;
  border: solid 1px $defaulblackbg;
  padding: 15px 40px;

  &:hover {
    background: $blackcolor;
    color: $defaultcolor;
    border: solid 1px $blackcolor;
  }
}
.desableBtn {
  background: $defaulblackbg;
  text-transform: uppercase;
  margin-top: 20px;
  color: $defaultcolor;
  display: inline-block;
  border: solid 1px $defaulblackbg;
  padding: 15px 40px;
  opacity: 0.4;
  transform: none;
  transition: none;
  &:hover {
    background: $defaulblackbg;
    cursor: default;
    border: solid 1px $defaulblackbg;
    color: $defaultcolor;
    transform: translateY(0px);
    box-shadow: none;
  }
}

.previousBtn {
  background: transparent;
  text-transform: uppercase;
  margin-top: 20px;
  color: $blackcolor;
  display: inline-block;
  border: solid 1px $blackcolor;
  padding: 15px 40px;
  margin-right: 20px;
  &:hover {
    background: $blackcolor;
    color: $defaultcolor;
  }
}

.flex {
  @include flex;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.textUppercase {
  text-transform: uppercase;
}
.textCenter {
  text-align: center;
}
.fullwidth {
  @include full-width;
}

.heading {
  @include heading;
}
.content {
  @include content;
}
.backheading {
  background: $defaultBlue;
  padding: 15px;
  color: $defaultcolor;
  font-size: 17px;
  text-transform: uppercase;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}


input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: transparent !important;
  color: $defaultcolor !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: $defaulblackbg !important;
  border-width: 1px !important;
}
.css-yk16xz-control {
  border: solid 1px $thinlightgrey;
  border-radius: 5px;
}
.css-2b097c-container {
  position: relative;
  box-sizing: border-box;
  margin-top: 10px;
}
.css-g1d714-ValueContainer {
  padding: 7px 8px;
}
.errorMessage {
  color: red;
  font-size: 0.9rem;
}
.closeBtnx {
  background: transparent;
  border: none;
  cursor: pointer;
}
.positionBtn {
  position: relative;
  right: 0;
  bottom: 0;
  text-align: right;
  margin-top: 30px;
  justify-content: flex-end;
  @include flex;
  @media screen and (max-width: 767px) {
    width: 100%;
    @include justify-center;
  }
}
.positionBtn3 {
  position: relative;
  right: 0;
  bottom: 0;
  text-align: right;
  margin-top: 30px;
  justify-content: flex-end;
  @include flex;
  @media screen and (max-width: 767px) {
    width: 100%;
    @include justify-center;
  }
}
.positionBtn2 {
  position: relative;
  text-align: right;
  margin-top: 30px;
  padding-left: 17%;
  @include flex;
  @media screen and (max-width: 767px) {
    width: 100%;
    @include justify-center;
    padding-left: 0%;
  }
  button {
    margin-right: 10px;
  }
}
.passIcon {
  margin-left: 0 !important;
}
.dBlock {
  display: block;
}
.chipcSec {
  margin-bottom: 15px;
  width: 100%;
  font-weight: 500;
}
#splide01-track {
  height: 100% !important;
}
.sc2::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.sc2::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 100px;
}
.sc2::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border-radius: 100px;
}
.dFlex {
  @include flex;
  @include justify-space-between;
}
.w45 {
  width: 48% !important;
  &::after {
    top: 23px !important;
  }
  .formSelect {
    margin-top: 5px !important;
    padding-bottom: 10px !important;
  }
}
.dFlex2 {
  @include flex;
  @include align-center;
}
.tRedio {
  @include flex;
  @include align-center;
  margin-right: 50px;
  margin-top: 5px;
  input {
    width: 22px;
    height: 20px;
    float: left;
    margin-top: -1px;
    margin-right: 10px;
  }
}
.pinkText {
  color: $lightpink;
}
.rdt {
  input {
    width: 94%;
    border: solid 1px #e1e1e1;
    border-radius: 5px;
    padding: 12px 12px 15px 15px;
    // margin-top: 5px;
    resize: none;
    font-size: 0.9rem;
    &:focus {
      outline: none;
      border: solid 1px #003b77;
    }
  }
}

.scroller::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}
.scroller::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.scroller::-webkit-scrollbar-thumb {
  background: #454545;
}
.scroller::-webkit-scrollbar-thumb:hover {
  background: #454545;
}
.scroller {
  scrollbar-color: #454545 #f1f1f1;
  scrollbar-width: thin;
}

.tableList {
  display: inline-block;
  text-align: center;
  width: 100%;
  margin-top: 20px;
  border-collapse: separate;
  font-weight: 400;
  @media screen and (max-width: 650px) {
    display: block;
  }
  .theader {
    @include flex;
    justify-content: space-around;
    @media screen and (max-width: 650px) {
      display: none;
    }
    .tableHeader {
      @include flex;
      @include align-center;
      @include justify-center;
      color: $blackcolor;
      padding-top: 10px;
      padding-bottom: 10px;
      font-weight: 500;
      width: 12%;
      &:first-child {
        width: 25%;
        padding-left: 30px;
        justify-content: flex-start;
      }
      .fronCheck {
        width: 20px;
        height: 20px;
        margin-right: 20px;
      }
    }
  }
}

.tableRow {
  @include flex;
  @include align-center;
  justify-content: space-around;
  background: $defaultcolor;
  border: solid 1px #e4e4e4;
  padding: 10px 0;
  margin-top: 12px;
  border-radius: 5px;
  text-align: left;
  @media screen and (max-width: 650px) {
    display: table;
    width: 100%;
    border-collapse: separate;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .fronCheck {
    width: 20px;
    height: 20px;
    margin-right: 20px;
    @media screen and (max-width: 650px) {
      position: absolute;
      top: 20px;
      left: -56%;
    }
  }
  &:hover {
    background: #fffafa;
    cursor: pointer;
    .tIconSec {
      opacity: 1;
    }
  }
  .tIconSec {
    opacity: 0;
  }
  .tableSmall {
    @include flex;
    @include justify-center;
    width: 15%;
    @media screen and (max-width: 650px) {
      display: table-row;
    }
    &:first-child {
      width: 30%;
      padding-left: 30px;
      justify-content: flex-start;
      @media screen and (max-width: 650px) {
        padding-left: 0;
      }
    }

    .greenText {
      color: $greenbg;
      cursor: pointer;
    }
    .redText {
      color: $red;
      cursor: pointer;
    }
    .yellowText {
      color: orange;
      cursor: pointer;
    }
  }
  .tableCell {
    padding-top: 3px;
    padding-bottom: 3px;
    text-align: center;
    @include flex;
    @include align-center;
    @media screen and (max-width: 650px) {
      display: table-cell;
      width: 50%;
      border-bottom: solid 1px #e4e4e4;
      position: relative;
    }
    span {
      @media screen and (max-width: 650px) {
        margin-top: 55px !important;
        position: absolute;
        top: 0;
        width: 100%;
        text-align: center;
        left: 0;
      }
    }
    &:nth-child(odd) {
      display: none;
      color: $blackcolor;
      padding-top: 10px;
      padding-bottom: 10px;
      @media screen and (max-width: 650px) {
        display: table-cell;
        width: 50%;
        background: #faf5f5;
        font-weight: 700;
      }
    }
    .videoTcol {
      @include flex;
      justify-content: flex-start;
      @media screen and (max-width: 650px) {
        @include justify-center;
        padding: 10px 0;
      }
      .imgCol {
        width: 80px;
        height: 80px;
        margin-right: 15px;
        @media screen and (max-width: 650px) {
          width: 60px;
          height: 60px;
          border-radius: 100%;
        }
        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          object-fit: cover;
          @media screen and (max-width: 650px) {
            border-radius: 100%;
          }
        }
      }
      .imgTextcol {
        width: 55%;
        text-align: left;
        h3 {
          font-size: 1rem;
          color: $blackcolor;
          margin: 0;
          white-space: nowrap;
          width: 130px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        p {
          font-size: 0.9rem;
          color: $grey;
          margin: 7px 0 10px;
        }
        .iconTr {
          color: $lightpink;
          background: transparent;
          border: none;
          padding: 0;
          svg {
            color: $lightpink;
            font-size: 20px;
            margin: 0 3px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.video {
  height: 290px !important ;
  background: $blackcolor;
  @media screen and (max-width: 767px) {
    width: 100% !important;
  }
}
.fileUpload {
  position: relative;
  overflow: hidden;
  position: relative;
  top: 0;
  right: 0;
  cursor: pointer;
  .upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
    width: 100%;
    height: 100%;
    text-indent: -999px;
  }
}
.btnBrowse {
  border-left: 0;
  border-radius: 5px;
  background-color: #ccc;
  color: black;
  height: 28px;
  padding: 12px 14px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 0.9rem;
}
.marginTop50 {
  margin-top: 50px !important;
}
.pagination {
  list-style: none;
  @include flex;
  margin: 0;
  padding: 0;
  justify-content: flex-end;
  .active {
    a {
      background: $lightpink;
      color: $defaultcolor;
    }
  }
  li {
    margin: 0 3px;
    a {
      width: 30px;
      height: 35px;
      border-radius: 5px;
      background: $defaultcolor;
      @include flex;
      @include justify-center;
      align-items: center;
      text-decoration: none;
      color: $blackcolor;
      cursor: pointer;
    }
  }
}
.dotedText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 300px;
}
.dotedText200 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
  text-align: left;
}

.formSelectTime {
  div.css-1insrsq-control {
    min-height: 48px !important;
  }
}

.dotedText200 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
  text-align: left;
  a {
    color: $grey;
  }
}

.pRelative {
  position: relative;
}

.submenuList {
  list-style: none;
  padding: 0;
  margin: 0 0 0 20px;
}
.noNoteSec {
  @include flex;
  @include justify-center;
  @include align-center;
  height: 70%;
  svg {
    color: #ccc;
    font-size: 2rem;
    font-weight: 200;
  }
}
.cursorPoint {
  cursor: pointer;
}
.termVtext {
  display: block;
  margin-top: 20px;
}
.elText {
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tableViewBtn {
  border: solid 1px #009f3c;
  border-radius: 10px;
  padding: 7px 15px;
  border-radius: 5px;
  color: #009f3c;
}
.reportTextels {
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.reportPop {
  z-index: 999999 !important;
}
.reportPopBody {
  position: relative;
  width: 800px;
  max-width: 800px;
  h2 {
    font-size: 17px;
    border-bottom: solid 1px #ccc;
    padding: 15px 20px;
    margin: 0;
  }
  .popClose {
    position: absolute;
    top: 8px;
    right: 15px;
    cursor: pointer;
  }
  .reportSec {
    padding: 10px 20px;
    margin-bottom: 10px;
    h3 {
      font-size: 1rem;
      margin: 0;
    }
    p {
      margin: 5px 0;
    }
  }
  .reportSecborder {
    border: solid 1px #ececec;
    border-radius: 5px 5px 0 0;
    padding: 15px;
  }
  .rdFlex {
    display: flex;
    /* margin-top: 20px; */
    align-items: center;
    background: #ececec;
    padding: 15px;
    border-radius: 0 0 5px 5px;
    .rdFlexImg {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      margin-right: 15px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        object-fit: cover;
      }
    }
    .rdFlexCon {
      h4 {
        font-size: 1rem;
        margin: 0;
      }
      p {
        margin: 0;
      }
      dt {
        font-size: 0.8rem;
        margin-bottom: 0;
        line-height: 1.5;
      }
    }
  }
  .scroller {
    height: 400px;
    overflow-y: scroll;
    width: 100%;
  }
}

.priceIcon{
  position: relative;
  &::before{
    font-family: 'Fontawesome';
    content: '\f156';
    margin-right: 5px;
  }
}

.crslItmShadow{
  .item{
    padding: 5px;
  }
  .owl-dots{
    display: none;
  }
  .owl-nav{
    button{
      width: 50px;
      height: 50px;
      background-color: #ffffff !important;
      border-radius: 100% !important;
      text-align: center;
      line-height: 50px !important;
      box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
      position: absolute;
      top: 50%;
      margin-top: -25px !important;
      z-index: 2;
      span{
        font-size: 40px;
        color: #7e7f88;
        font-weight: normal;
      }
      &:hover{
        span{
          color: #de6b05;
        }
      }
      &.owl-prev{
        left: -25px;
      }
      &.owl-next{
        right: -25px;
      }
    }
  }
}

.viewBtn{
  text-align: center;
  margin-top: 40px;
}


