@import "../../assets/styles/shared.scss";
.bannerSec{
    width: 100%;
    position: relative;
    background: url(../../assets/images/banner-bg.png);
    background-position: 0 0;
    background-repeat: no-repeat;
    overflow: hidden;
    box-shadow: 0 5px 40px #d4f4fa;    
    .scrollimg{
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 100px;        
        left: 0;
        text-align: center;
        width: 100%;  
        @media (max-width: 991px){
            display: none;
        }     
    }
    .bannerRow{
        display: flex;
        justify-content: space-between;
        margin-top: 80px;
        @media (max-width: 991px){
            flex-wrap: wrap;
        } 
        .bannerColleft{
            width: 50%;
            @media (max-width: 991px){
                width: 100%;
                order: 2;
                text-align: center;
            } 
            h1{
				@include sunborn;
                font-size: 60px;
                color: $blackcolor;
                line-height: 1.1;
                padding-top: 70px;
                @media (max-width: 991px){
                    font-size: 30px;
                }
            }
            p{
                font-size: 17px;
                color: #444444;
                font-weight: 500;
                line-height: 1.7;
                padding: 30px 0 40px;                
            }
            a{
                margin-right: 13px;
                img{
                    @media (max-width: 991px){
                        width: 42%;
                    }
                }
               
            }
            .feedBacksec{
                background: #ddf1f8;
                width: 345px;
                border-radius: 200px 200px 0 0;
                padding: 50px 0;
                text-align: center;
                margin-top: 50px;
                @media (max-width: 991px){
                    margin: 50px auto 0;
                }
                h2{
                font-size: 60px;
                color: $blackcolor;
                font-weight: 600; 
                line-height: 1.1;               
                }
                p{
                font-size: 17px;
                color: $blackcolor;   
                padding: 0;                               
                }
                ul{
                    display: flex;
                    list-style: none;
                    justify-content: center;
                    margin: 20px 0 0 25px;
                    padding: 0;
                    li{
                        position: relative;
                        margin-left: -25px;
                        img{
                            width: 50px;
                            height: 50px;
                            border-radius: 100%;
                            border: solid 3px $defaultcolor;
                        }
                    }
                }
            }
        }
    .bannerColright{      
      position: relative; 
      @media (max-width: 991px){
        order: 1;
        width: 100%;
        overflow: hidden;
        height: 700px;
      } 
      .banImgSec{
        position: absolute;
        top: -81px;
        right: -320px;
        @media (max-width: 991px){
            position: relative;       
            right: 62px;
        }
        img{
            margin-right: -100px;
            @media (max-width: 991px){
                margin-right: 0;                
            }
          } 
      }   
       
    }
    }
}


.serviceSec{
    width: 100%;
    padding: 100px 0;
    @media (max-width: 767px){
        padding: 50px 0;
    }
    h2{
		@include sunborn;
        font-size: 50px;
        color: $blackcolor;
        line-height: 1.4;
        padding-bottom:50px;
        text-align: center;
        @media (max-width: 1199px){
            font-size: 40px;
        }
        @media (max-width: 767px){
            font-size: 30px;
            padding-bottom: 10px;
        }
        @media (max-width: 576px){
            font-size: 25px;
        }
        span{
            color: $skyblue;
        }
    }
    ul{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        list-style: none;
        padding: 0;
        margin: 0;
        li{
            width: 23%;
            margin: 0;
            border-radius: 7px;
            background: $defaultcolor;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            padding: 25px;
            border-bottom: solid 3px $blackcolor;
            @media (max-width: 991px){
                width: 48%;
                margin-top: 30px;
            }
            @media (max-width: 479px){
                width: 100%;
            }
            img{
                margin: 10px 0 5px;
            }
            h3{
                padding: 10px 0;
                font-size: 16px;                
            }
            p{
                color: #7f7f7f;
                font-size: 15px;  
            }
            a{
                color: $skyblue;
                padding: 15px 0 0;
                display: block;
                text-decoration: none;
            }
            &:hover{
                background: $blackcolor;
                box-shadow: 0 0 10px $skyblue;
                cursor: pointer;
                border-bottom: solid 3px $skyblue;
                h3{                    
                    color: $defaultcolor;                    
                }
            }
        }
    }
}

.mt40{
    margin-top: 40px;
    display: flex;
    justify-content: flex-start;
    a{
        margin-right: 15px;
        width: 45%;
        img{
            width: 100%;
        }
    }
}

.aboutSec{
    background: $defaulblackbg;
    padding: 60px 0;
    @media (max-width: 767px){
        padding: 50px 0;
    }
    .aboutPrtRow{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @media (max-width: 1199px){
            align-items: center;
        }
        .aboutLeftcol{
            width: 40%;
            @media (max-width: 991px){
                width: 100%;
            }
            h2{
                @include sunborn;
                font-size: 50px;
                color: $defaultcolor;
                text-transform: uppercase;
                padding-top: 110px;
                @media (max-width: 1199px){
                    font-size: 40px;
                    padding-top: 0;
                }
                @media (max-width: 767px){
                    font-size: 30px;
                }
                @media (max-width: 576px){
                    font-size: 25px;
                }
                span{
                    color: $skyblue;                
                }
            }
            p{
                font-size: 15px;
                color: #dddddd;
                padding: 20px 0;
                font-weight: 400;
                line-height: 1.9;
            }
            a.aboutMoreBtn{
                display: inline-block;
                font-size: 15px;
                color: #ffffff;
                background-color: $skyblue;
                padding: 12px 30px;
                border-radius: 6px;
                text-decoration: none;
                margin-top: 30px;
                @media (max-width: 767px){
                    margin-top: 15px;
                }
                &:hover{
                    background-color: $blackcolor;
                }
                i{
                    margin-left: 5px;
                }
            }
            
        }
        .aboutRightcol{
            width: 45%;
            @media (max-width: 991px){
                width: 100%;
                text-align: center;
                margin-top: 50px;
            }
            img{
                @media (max-width: 1199px){
                    width: auto;
                    max-width: 100%;
                }
            }
        }
    }
}


.howItWorksSec{
	position: relative;
	padding: 100px 0;
    @media (max-width: 767px){
        padding: 50px 0;
    }
	.howItHdn{
		position: relative;
		margin-bottom: 10px;
		h2{
			@include sunborn;
			font-size: 60px;
			color: $blackcolor;
			text-align: center;
			text-transform: uppercase;
            @media (max-width: 1199px){
                font-size: 40px;
            }
            @media (max-width: 767px){
                font-size: 30px;
            }
            @media (max-width: 576px){
                font-size: 25px;
            }
			span{
				color: $skyblue;
			}
		}
	}
	.howItWorksRow{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		margin-top: 100px;
        &:nth-child(3){
            .howItWorksTextClm{
                order: 1;
                @media (max-width: 991px){
                    order: 2;
                }
            }
            .howItWorksImgClm{
                order: 2;
                @media (max-width: 991px){
                    order: 1;
                }
            }
        }
        @media (max-width: 991px){
            margin-top: 20px;
        }
		.howItWorksImgClm{
			width: 45%;
			text-align: center;
            @media (max-width: 991px){
                width: 100%;
            }
            img{
                @media (max-width: 1199px){
                    width: auto;
                    max-width: 100%;
                }
                @media (max-width: 767px){
                    max-height: 400px;
                }
                @media (max-width: 576px){
                    max-height: 350px;
                }
            }
		}
		.howItWorksTextClm{
			width: 45%;
			padding: 0 30px;
            @media (max-width: 991px){
                width: 100%;
                padding: 30px 0;
            }
			h3{
				@include sunborn;
				font-size: 35px;
				color: $blackcolor;
				margin-bottom: 20px;
                @media (max-width: 1199px){
                    font-size: 25px;
                }
                @media (max-width: 767px){
                    font-size: 20px;
                    margin-bottom: 10px;
                }
			}
			p{
				font-size: 15px;
				color: #494949;
				line-height: 26px;
				margin-bottom: 15px;
			}
			a.howItWorksMoreBtn{
				display: inline-block;
				font-size: 15px;
				color: #ffffff;
				background-color: $skyblue;
				padding: 12px 30px;
				border-radius: 6px;
				text-decoration: none;
				margin-top: 30px;
                @media (max-width: 767px){
                    margin-top: 15px;
                }
				&:hover{
					background-color: $blackcolor;
				}
				i{
					margin-left: 5px;
				}
			}
		}
	}
}

.testimonialSec{
	position: relative;
	.testimonialHdnPrt{
		position: relative;
		background-color: #c3edf4;
		padding: 100px 0 100px 0;
		min-height: 460px;
        @media (max-width: 767px){
            padding-top: 50px;
        }
        @media (max-width: 576px){
            min-height: 380px;
        }
		h2{
            position: relative;
			@include sunborn;
			font-size: 50px;
			color: $blackcolor;
			text-transform: uppercase;
            z-index: 2;
            @media (max-width: 1199px){
                font-size: 40px;
            }
            @media (max-width: 767px){
                font-size: 30px;
            }
            @media (max-width: 576px){
                font-size: 25px;
            }
		}
		p{
            position: relative;
			font-size: 19px;
			color: #424242;
			font-weight: normal;
			margin-top: 15px;
            z-index: 2;
            @media (max-width: 1199px){
                font-size: 16px;
            }
		}
		&::after{
			position: absolute;
			content: '';
			width: 690px;
			height: 100%;
			bottom: 0;
			right: 0;
			background: url(../../assets/images/testimonial-hdn-bg.png);
			background-position: 0 0;
			background-repeat: no-repeat;
			background-size: cover;
		}
	}
	.item{
		padding: 20px 15px;
	}
	.testimonialScrollPrt{
		position: relative;
		margin-top: -200px;
		.testimonialItem{
			position: relative;
			background-color: #ffffff;
			box-shadow: 0 2px 7px rgba(0, 0, 0, 0.08);
			border-radius: 6px;
			padding: 25px 50px 25px 30px;
			@include transition;
			&:hover{
				background-color: #424242;
				box-shadow: 0 1px 15px rgba(67, 228, 255, 0.6);
				p, h5{
					color: #ffffff;
				}
			}
			img{
				width: auto;
				display: inline-block;
			}
			p{
				font-size: 14px;
				color: #747373;
				font-weight: normal;
			}
			h4{
				font-size: 15px;
				color: $skyblue;
				font-weight: 500;
				position: relative;
				margin-top: 25px;
				&::after{
					position: absolute;
					content: '';
					width: 40px;
					height: 2px;
					background-color: $skyblue;
					top: 11px;
					margin-left: 20px;
				}
			}
			h5{
				font-size: 14px;
				color: #747373;
				font-weight: normal;
				margin-top: 5px;
			}
			.testimonialRatingRow{
				margin-bottom: 20px;
				ul{
					padding: 0;
					margin: 0;
					list-style: none;
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					li.testimonialClientImg{
						margin-right: 25px;
						img{
							width: 75px;
							height: 75px;
							border-radius: 100%;
							object-fit: cover;
						}
					}
					li.testimonialStarIcon{
						img{
							width: auto;
							height: 18px;
						}
					}
				}
			}
		}
	}
}

