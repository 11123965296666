@import "../../assets/styles/shared.scss";
.inrBannerSec{
    position: relative;
    width: 100%;
    box-shadow: 0 5px 40px #d4f4fa;
    z-index: 2;
    .inrBnrImg{
        img{
            width: 100%;
            display: block;
            @media (max-width: 767px){
                min-height: 200px;
                object-fit: cover;
            }
        }
    }
    .inrBnrCap{
        text-align: center;
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        @include translateY;
        h1{
            @include sunborn;
            font-size: 60px;
            color: $blackcolor;
            line-height: 1.1;
            @media (max-width: 1199px){
                font-size: 50px;
            }
            @media (max-width: 991px){
                font-size: 40px;
            }
            @media (max-width: 767px){
                font-size: 30px;
                margin-top: 50px;
            }
            @media (max-width: 576px){
                font-size: 25px;
            }
            span{
                color: $skyblue;
            }
        }
    }
}


.termsConditionBodySec{
    position: relative;
    padding: 100px 0;
    @media (max-width: 767px){
        padding: 50px 0 0 0;
    }
    h2{
        @include sunborn;
        font-size: 30px;
        color: $blackcolor;
        padding: 15px 0;
        @media (max-width: 767px){
            font-size: 20px;
        }
    }
    p{
        font-size: 15px;
        color: #494949;
        line-height: 26px;
        margin-bottom: 20px;
        @media (max-width: 767px){
            font-size: 14px;
        }
    }
}



.inrAboutAppSec{
    position: relative;
    padding: 350px 0 100px 0;
    text-align: center;
    background-color: #242529;
    @media (max-width: 767px){
        padding-top: 300px;
        padding-bottom: 50px;
    }
    @media (max-width: 576px){
        padding-top: 200px;
    }
    .inrAboutAppMobImg{
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 350px;
        @media (max-width: 767px){
            bottom: 240px;
        }
        @media (max-width: 576px){
            bottom: 190px;
        }
        img{
            @media (max-width: 991px){
                max-width: 90%;
            }
        }
    }
    h2{
        @include sunborn;
        font-size: 50px;
        color: #d7d7d7;
        text-transform: uppercase;
        margin: 10px 0;
        @media (max-width: 767px){
            font-size: 35px;
        }
        @media (max-width: 576px){
            font-size: 25px;
        }
        @media (max-width: 479px){
            font-size: 22px;
        }
    }
    ul{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0;
        margin: 0;
        list-style: none;
        li{
            margin: 10px;
            @media (max-width: 576px){
                margin: 10px 5px;
            }
            img{
                max-width: 300px;
                @media (max-width: 767px){
                    max-width: 200px;
                }
                @media (max-width: 576px){
                    max-width: 150px;
                }
                @media (max-width: 479px){
                    max-width: 125px;
                }
            }
        }
    }
}

.pb500{
    padding-bottom: 450px;
}





