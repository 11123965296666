@import "../../assets/styles/shared.scss";

.footerSec{
    background: $descriptionText;
    @include full-width;
	margin-top: 0;
	.footerSubscribePrt{
        position: relative;
        padding: 40px 0 120px 0;
		@media (max-width: 767px){
			padding-bottom: 50px;
		}
        background-color: #ffffff;
        h2{
			@include sunborn;
			font-size: 55px;
			color: $blackcolor;
			text-transform: uppercase;
			@media (max-width: 1199px){
                font-size: 40px;
            }
			@media (max-width: 767px){
                font-size: 30px;
            }
			@media (max-width: 576px){
				font-size: 25px;
			}
			span{
				color: $skyblue;
			}
		}
		h3{
			font-size: 19px;
			color: #424242;
			font-weight: normal;
			margin-top: 10px;
			@media (max-width: 1199px){
                font-size: 16px;
            }
		}
		.subscribeForm{
			position: relative;
			margin-top: 70px;
			display: flex;
			justify-content: center;
			@media (max-width: 767px){
				margin-top: 40px;
			}
			input{
				width: 30%;
				height: 60px;
				background-color: #e5f9fc;
				border: 1px solid #d0d0d0;
				padding: 5px 30px;
				border-radius: 40px 0px 0px 40px;
				font-size: 14px;
				color: #4f4f4f;
				outline: none;
				box-shadow: none;
				@media (max-width: 991px){
					width: 50%;
				}
				@media (max-width: 767px){
					width: 70%;
				}
				@media (max-width: 479px){
					padding: 5px 15px;
					height: 50px;
				}
				&:focus,
				&:focus-visible{
					border: 1px solid #d0d0d0;
				}
			}
			button{
				width: auto;
				height: 60px;
				background-color: #424242;
				border: none;
				padding: 5px 25px;
				border-radius: 0px 40px 40px 0px;
				font-size: 14px;
				color: #ffffff;
				text-transform: uppercase;
				cursor: pointer;
				outline: none;
				box-shadow: none;
				@media (max-width: 479px){
					font-size: 13px;
					padding: 5px 15px;
					height: 50px;
				}
				&:hover{
					background-color: #242529;
				}
			}
		}
    }


    .footerMenuPrt{
		position: relative;
		background-color: #242529;
        padding: 110px 0;
		@media (max-width: 767px){
			padding: 50px 0;
		}
		.flxRow{
            @include flex;
			flex-wrap: wrap;
            @include justify-space-between;
		}
		.footerClm{
			position: relative;
			width: 25%;
			@media (max-width: 991px){
				width: 50%;
			}
			@media (max-width: 479px){
				width: 100%;
			}
			h3{
				@include sunborn;
				font-size: 17px;
				color: #ffffff;
				text-transform: uppercase;
				letter-spacing: 1px;
				margin: 0 0 25px 0;
			}
			p{
				position: relative;
				font-size: 14px;
				color: #d7eaf5;
				font-weight: normal;
				line-height: 1.8;
				margin-top: 15px;
			}
			ul{
				margin: 0;
				padding: 0;
				list-style: none;
				li{
					margin-top: 15px;
				}
			}
		}
		.footerLogoClm{
			h4{
				font-size: 16px;
				color: #ffffff;
				font-weight: 500;
				margin: 30px 0 15px 0;
			}
			input{
				font-size: 13px;
				color: #d7eaf5;
				width: 100%;
				height: 50px;
				background-color: #2f3135;
				padding: 5px 50px 5px 20px;
				border-radius: 12px;
				box-shadow: none;
				outline: none;
				border: none;
				&::placeholder { color: #d7eaf5;; }
				&::-webkit-input-placeholder { color: #d7eaf5;; }
				&::-moz-placeholder { color: #d7eaf5;; }
				&:-moz-placeholder { color: #d7eaf5;; }
				&:-ms-input-placeholder { color: #d7eaf5;; }
			}
		}
		.footerMenuClm{
			padding-left: 80px;
			@media (max-width: 479px){
				padding-left: 0;
				margin-top: 40px;
			}
			ul{
				li{
					font-size: 13px;
					a{
						display: inline-block;
						color: #d7eaf5;
						text-decoration: none;
						&:hover{
							color: $skyblue;
						}
					}
				}
			}
		}
		.footerContactClm{
			padding-left: 20px;
			@media (max-width: 991px){
				padding-left: 0;
				margin-top: 40px;
			}
			ul{
				li{
					font-size: 13px;
					color: #d7eaf5;
					display: flex;
					align-items: flex-start;
					p{
						font-size: 13px;
						color: #d7eaf5;
						margin: 0;
					}
					img{
						margin: 6px 13px 0 0;
					}
				}
			}
		}
		.footerSocialClm{
			padding-left: 100px;
			@media (max-width: 991px){
				padding-left: 80px;
				margin-top: 40px;
			}
			@media (max-width: 479px){
				padding-left: 0;
			}
			ul{
				li{
					font-size: 13px;
					a{
						display: flex;
						align-items: center;
						color: #d7eaf5;
						text-decoration: none;
						&:hover{
							color: $skyblue;
						}
						i{
							font-size: 22px;
							color: #ffffff;
							margin-right: 15px;
							vertical-align: middle;
						}
					}
				}
			}
		}
    }




    .footerCopyrightPrt{
		position: relative;
        background: #191919;
        padding: 20px 0;
		p{
			font-size: 14px;
			color: #9b9b9b;
			text-align: center;
		}
    }

    

}