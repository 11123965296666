@import "../../assets/styles/shared.scss";
.header{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 5;
    padding-top: 10px;
    @media (max-width: 991px){
        padding-top: 0;
    }
    .container{
        @media (max-width: 991px){
            position: relative;
        } 
    }
.headrRow{
    display: flex;
    justify-content: space-between;

 }
}

