@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sofia&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap');
@font-face {
  font-family: 'javatext';
  src: url('../fonts/javatext.eot');
  src: url('../fonts/javatext.eot') format('embedded-opentype'),
      url('../fonts/javatext.woff2') format('woff2'),
      url('../fonts/javatext.woff') format('woff'),
      url('../fonts/javatext.ttf') format('truetype'),
      url('../fonts/javatext.svg#javatext') format('svg');
}

@font-face {
  font-family: 'SunbornSansOne';
  src: url('../fonts/SunbornSansOne.eot');
  src: url('../fonts/SunbornSansOne.eot') format('embedded-opentype'),
       url('../fonts/SunbornSansOne.woff2') format('woff2'),
       url('../fonts/SunbornSansOne.woff') format('woff'),
       url('../fonts/SunbornSansOne.ttf') format('truetype'),
       url('../fonts/SunbornSansOne.svg#SunbornSansOne') format('svg');
}

// @font-face {
//   font-family: 'sunbornsans_one';
//   src: url('../fonts/sunborn-sansone-webfont.woff2') format('woff2'),
//        url('../fonts/sunborn-sansone-webfont.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;

// }

// @font-face {
//   font-family: 'sunbornsans_one_outline';
//   src: url('../fonts/sunborn-sansoneoutline-webfont.woff2') format('woff2'),
//        url('../fonts/sunborn-sansoneoutline-webfont.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;

// }

// @font-face {
//   font-family: 'sunbornsans_one_outline_slant';
//   src: url('../fonts/sunborn-sansoneoutlineslant-webfont.woff2') format('woff2'),
//        url('../fonts/sunborn-sansoneoutlineslant-webfont.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;

// }

// @font-face {
//   font-family: 'sunbornsans_one_slant';
//   src: url('../fonts/sunborn-sansoneslant-webfont.woff2') format('woff2'),
//        url('../fonts/sunborn-sansoneslant-webfont.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;

// }

// @font-face {
//   font-family: 'sunbornscript';
//   src: url('../fonts/sunborn-script-webfont.woff2') format('woff2'),
//        url('../fonts/sunborn-script-webfont.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;

// }