*{
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
html{
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a, input, textarea, button{
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fa-paper-plane{
  position: absolute;
  font-size: 16px !important;
  color: #ffffff;
  bottom: 16px;
  right: 18px;
}

.homeTestiCrsl .owl-nav{
  position: absolute;
  top: -145px;
  right: 10px;
}
.homeTestiCrsl .owl-nav button{
  display: inline-block;
  width: 60px;
  height: 60px;
  background-color: #424242 !important;
  border-radius: 100% !important;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.homeTestiCrsl .owl-nav button:hover{
  background-color: #81bfed !important;
}
.homeTestiCrsl .owl-nav .owl-prev{
  background-image: url(../public/images/left-arrow.png) !important;
  background-repeat: no-repeat !important;
  background-position: 18px 23px !important;
}
.homeTestiCrsl .owl-nav .owl-next{
  background-image: url(../public/images/right-arrow.png) !important;
  background-repeat: no-repeat !important;
  background-position: 18px 23px !important;
}
.homeTestiCrsl .owl-nav span{
  font-size: 0;
}


.headerMenu ul{
      list-style: none;
      padding: 0;
      margin: 0 0 0;
      display: flex;
}
.headerMenu ul li{
  font-size: 15px;
  text-transform: uppercase;
  color: #000;
  padding: 20px;
  /* font-weight: 400; */
  /* font-family: 'Berkshire Swash', cursive; */
} 
.headerMenu ul li a{
  color: #000;
  text-decoration: none;
  padding-bottom: 5px;
} 
.headerMenu ul li a:hover{
  color: #3c90e6;
  border-bottom: solid 3px #3c90e6;
}  
.headerMenu ul li a.active{
  color: #3c90e6;
  border-bottom: solid 3px #3c90e6;
}
i.fa-bars{
  display: none;
  font-size: 25px;
  margin-top: 25px;
  position: absolute;
  right: 15px;
  top: 0;
}
        







/*======================== Responsive Style ========================*/
@media (max-width: 991px){
  .homeTestiCrsl .owl-nav{
    top: inherit;
    bottom: -75px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .homeTestiCrsl .owl-nav button{
    width: 50px;
    height: 50px;
  }
  .homeTestiCrsl .owl-nav .owl-prev,
  .homeTestiCrsl .owl-nav .owl-next{
    background-position: 11px 19px !important;
  }
  i.fa-bars{
    display: block;
  }
  .headerMenu ul{
    display: none;
  }
  .mobile-menu{
    background: #2f2f2f;
    position: absolute;
    top:72px;
    left: 0;
    width: 100%;
  }
  .mobile-menu ul{
    display: block;
  }
  .mobile-menu ul li a {
    color: #fff;
  }
  .header_container__0ivI6{
    position: relative;
  }  
}